<template>
  <div>
    <v-bottom-sheet
      v-if="bottomSheet && hasFilters > 0"
      v-model="openBottom"
      fullscreen
      scrollable
      persistent
    >
      <v-sheet>
        <v-layout align-center justify-center row class="mt-3">
          <v-flex xs9>
            <h3 style="text-transform: capitalize;">{{ $t(`misc.${title}`) }}</h3>
          </v-flex>
          <v-flex xs1 class="text-right">
            <v-icon
              v-on:click="openBottom = !openBottom"
            >
              mdi-close
            </v-icon>
          </v-flex>
        </v-layout>
        <v-layout align-center justify-center row>
          <v-flex xs10>
            <template v-if="formattedFiltersKeys.length === 1">
              <Fields
                :fields="formattedFilters[formattedFiltersKeys[0]]"
                v-on:change="change($event, [formattedFiltersKeys[0]])"
              />
            </template>
            <template v-else>
              <v-expansion-panels accordion>
                <v-expansion-panel
                  v-for="(key) in Object.keys(formattedFilters)"
                  :key="key"
                >
                  <v-expansion-panel-header>{{ $t(`misc.${key}`) }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Fields
                      :fields="formattedFilters[key]"
                      v-on:change="change($event, key)"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-flex>
        </v-layout>
      </v-sheet>
    </v-bottom-sheet>
    <div
      v-else-if="!bottomSheet"
    >
      <v-card v-if="hasFilters > 0">
        <v-card-text>
          <h3 style="text-transform: capitalize;">{{ $t(`misc.${title}`) }}</h3>
          <template v-if="formattedFiltersKeys.length === 1">
            <Fields
              :fields="formattedFilters[formattedFiltersKeys[0]]"
              v-on:change="change($event, [formattedFiltersKeys[0]])"
            />
          </template>
          <template v-else>
            <v-expansion-panels accordion>
              <v-expansion-panel
                v-for="(key) in formattedFiltersKeys"
                :key="key"
              >
                <v-expansion-panel-header>{{ $t(`misc.${key}`) }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Fields
                    :fields="formattedFilters[key]"
                    v-on:change="change($event, key)"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-card-text>
      </v-card>
      <div class="text-xs-center my-6" v-if="$slots.footer">
        <v-layout align-center justify-center row fill-height>
          <slot name="footer"></slot>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * List of components of filters
 */
export default {
  name: 'Filters',
  props: {
    title: {
      type: String,
      default: 'filters',
    },
    filters: {
      type: [Object, Array],
      default: () => {},
    },
    bottomSheet: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let formattedFilters = { ...this.filters };
    if (Array.isArray(this.filters)) {
      formattedFilters = {
        default: this.filters,
      };
    }
    return {
      openBottom: this.showFilters,
      formattedFilters,
    };
  },
  computed: {
    /**
     * Compute keys of filters
     *
     * @return {string[]} Return an array of keys
     */
    formattedFiltersKeys() {
      return Object.keys(this.formattedFilters);
    },
    /**
     * Compute count of filters
     *
     * @return {number} Returns the number of filters
     */
    hasFilters() {
      return this.formattedFiltersKeys
        .reduce((res, key) => (this.formattedFilters[key]
          .filter((f) => (f.component)).length + res), 0);
    },
  },
  watch: {
    /**
     * Update open state when showFilter change
     *
     * @param {boolean} value State of visibility of filters
     */
    showFilters(value) {
      this.openBottom = value;
    },
    /**
     * Emit hide event when filters are closed
     *
     * @param {boolean} value State of visibility of filters
     */
    openBottom(value) {
      if (!value) {
        this.$emit('hide');
      }
    },
    /**
     * Update formattedFilters when filters change
     */
    filters() {
      let formattedFilters = { ...this.filters };
      if (Array.isArray(this.filters)) {
        formattedFilters = {
          default: this.filters,
        };
      }

      this.formattedFilters = formattedFilters;
    },
  },
  methods: {
    /**
     * Emit filter result when an filter haved change
     */
    change(filters, name) {
      this.formattedFilters[name] = filters;

      let result = this.formattedFilters;
      if (Array.isArray(this.filters)) {
        result = this.formattedFilters.default;
      }

      this.$emit('update:filters', result);
    },
  },
};
</script>
