<template>
  <div>
    <Map
      :headers="headers"
      :hits="hits"
      :loading="loading"
      :state="state"
      v-on:update-map="updateMap"
    />
    <Filters
      class="filters"
      :filters="(hideFilters) ? [] : filters"
      :bottom-sheet="$vuetify.breakpoint.smAndDown"
      :show-filters="showFilters"
      v-on:change="updateFilters"
      v-on:hide="showFilters = false"
    >
      <template v-slot:footer>
        <v-layout align-center justify-center row fill-height>
          <div v-for="option in options" :key="option.alias || option.object">
            <v-btn
              v-if="option.permissions.indexOf('create') > -1"
              class="white--text"
              color="primary"
              link :to="{ path: option.itemRoute + '/' + 'new' }"
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t('crud.add') }} {{ $t(option.alias || option.object) }}
            </v-btn>
          </div>
        </v-layout>
      </template>
    </Filters>
    <div class="filters" v-if="$vuetify.breakpoint.smAndDown && !hideFilters">
      <v-icon
        v-on:click="showFilters = true"
      >
        mdi-filter-variant
      </v-icon>
    </div>
  </div>
</template>

<script>
import Backend from '@/backend';
import _ from '@/misc/lodash';
import { buildAllGeoObjects } from '@/misc/buildGeoObject';
import { queryBuilder } from '@/misc/QueryBuilder';
import Map from '@/components/Map.vue';
import Filters from '@/components/crud/Filters.vue';

// TODO this view need update
export default {
  components: {
    Map,
    Filters,
  },
  data() {
    let lat = 0;
    let lng = 0;
    let zoom = 0;
    if (this.$route.query.st) {
      const st = this.$route.query.st.split(',').map(Number.parseFloat);
      [lat, lng, zoom] = st;

      lat = Math.min(90, Math.max(-90, lat));
      lng = Math.min(180, Math.max(-180, lng));
    }

    return {
      state: {
        lat,
        lng,
        zoom: Math.max(3, zoom),
        bounds: null,
      },
      filters: [],
      loading: true,
      showFilters: false,
      hideFilters: false,
      currentObjectsKeys: null,
      firstLoad: true,
      autoRefresh: [],
      hits: [],
      fetchDataDebounce: _.debounce(() => {
        this.fetchData();
      }, 500),
    };
  },
  computed: {
    options() {
      let { options } = this.$store.state.settings.currentPage;
      if (!Array.isArray(options)) {
        options = [options];
      }
      return options;
    },
    headers() {
      const headers = {};
      this.options.forEach((option) => {
        headers[option.alias || option.object] = option.fields.read || [];
      });
      return headers;
    },
  },
  watch: {
    options() {
      // When we switch page without changing view
      let lat = 0;
      let lng = 0;
      let zoom = 0;
      if (this.$route.query.st) {
        const st = this.$route.query.st.split(',').map(Number.parseFloat);
        [lat, lng, zoom] = st;

        lat = Math.min(90, Math.max(-90, lat));
        lng = Math.min(180, Math.max(-180, lng));
      }

      this.state = {
        lat,
        lng,
        zoom: Math.max(3, zoom),
        bounds: null,
      };
      this.filters = this.computeFilters();
      this.loading = true;
      this.firstLoad = true;
      this.showFilters = false;
      this.fetchDataDebounce();
      this.subscribeAutoRefresh();
    },
  },
  mounted() {
    this.filters = this.computeFilters();
    this.subscribeAutoRefresh();
  },
  async beforeRouteLeave(to, from, next) {
    for (let index = 0; index < this.autoRefresh.length; index += 1) {
      // eslint-disable-next-line no-await-in-loop
      await Backend().realtime.unsubscribe(this.autoRefresh[index]);
    }
    next();
  },
  methods: {
    async subscribeAutoRefresh() {
      for (let index = 0; index < this.autoRefresh.length; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        await Backend().realtime.unsubscribe(this.autoRefresh[index]);
      }

      const applicationData = this.$store.state.settings.applications
        .find((app) => (app.id === this.$store.state.settings.currentApplication));

      for (let index = 0; index < this.options.length; index += 1) {
        const option = this.options[index];
        if (option.autoRefresh) {
          // eslint-disable-next-line no-await-in-loop
          await Backend().realtime.subscribe(
            option.autoRefresh,
            'docs',
            {
              bool: {
                must: [
                  {
                    equals: {
                      customer: applicationData.customer,
                    },
                  },
                  {
                    equals: {
                      application: applicationData.id,
                    },
                  },
                ],
              },
            },
            () => {
              this.fetchDataDebounce();
            },
          );
        }
      }
    },
    computeFilters() {
      const filters = {};
      this.hideFilters = true;

      this.options.forEach((option) => {
        const optionFilters = (option.fields.search || [])
          .map((filter) => {
            let value;
            if (typeof this.$route.query[filter.fid] !== 'undefined') {
              value = this.$route.query[filter.fid];
            }

            if (filter.default && !value && value !== false
              && Object.keys(this.$route.query).length === 0) {
              value = filter.default;
            }

            if ((filter.component === 'autocompleteField' || filter.component === 'selectField')
              && filter.multiple !== false
              && !Array.isArray(value) && typeof value !== 'undefined') {
              value = [value];
            }

            return { value, ...filter };
          });

        const hideFilters = optionFilters.filter((f) => (f.component)).length === 0;

        if (!hideFilters) {
          filters[option.alias || option.object] = optionFilters;
          this.hideFilters = false;
        }
      });

      return filters;
    },
    updateFilters(filters) {
      this.filters = filters;
      this.fetchDataDebounce();
    },
    updateMap(state) {
      this.state = state;
      this.fetchDataDebounce();
    },
    async fetchData() {
      this.loading = true;

      this.$nextTick(async () => {
        const result = [];
        const route = {};
        const routeQuery = {
          st: `${this.state.lat},${this.state.lng},${this.state.zoom}`,
        };

        for (let index = 0; index < this.options.length; index += 1) {
          let activeFilter = false;
          const option = this.options[index];
          const query = queryBuilder(option.queryTemplate);
          if (this.filters[option.alias || option.object]) {
            this.filters[option.alias || option.object].forEach((filter) => {
              let { value } = filter;
              // If at least one filter is active
              if (activeFilter === false && (value && value.length > 0) === true) {
                activeFilter = true;
              }

              if (filter.bounds && this.state.bounds) {
                const { bounds } = this.state;
                bounds._northEast.lng = Math.min(180, Math.max(-180, bounds._northEast.lng));
                bounds._southWest.lng = Math.min(180, Math.max(-180, bounds._southWest.lng));
                bounds._northEast.lat = Math.min(90, Math.max(-90, bounds._northEast.lat));
                bounds._southWest.lat = Math.min(90, Math.max(-90, bounds._southWest.lat));

                value = [
                  [bounds._northEast.lng, bounds._southWest.lat],
                  [bounds._southWest.lng, bounds._northEast.lat],
                ];
                // ! WARNING : if the location used for display is not part of the object document,
                // !           the bounds filter will not work.
              }

              query.addFilter(
                filter.query.path,
                filter.query.template,
                value,
                filter.query.array,
              );
            });
          }

          // eslint-disable-next-line no-await-in-loop
          const { hits } = await this.$store.dispatch('crud/SEARCH', {
            object: option.object,
            alias: option.alias || option.object,
            plugin: option.plugin,
            body: {
              q: query.getQuery(),
            },
            all: true,
          });

          if (typeof hits !== 'undefined') {
            const geoHeader = option.fields.read.find((header) => (header.marker || header.shape));
            if (geoHeader) {
            // Push Computed geoObject
              // eslint-disable-next-line no-await-in-loop
              result.push(...(await buildAllGeoObjects(
              // Only current objects
                hits.filter((hit) => hit.current),
                // Merge the options and geoHeader for an complete header
                { ...option, ...geoHeader },
                // activeFilter Define if is highlighted
                activeFilter,
              )));
            }
          }

          if (this.filters[option.alias || option.object]) {
            this.filters[option.alias || option.object].forEach((filter) => {
              if (!filter.bounds
                && typeof filter.value !== 'undefined' && (typeof filter.value === 'boolean' || (filter.value && (!Array.isArray(filter.value) || filter.value.length > 0)))) {
                routeQuery[filter.fid] = filter.value;
              }
            });
          }
        }
        Object.assign(route, this.$route, { query: routeQuery });

        this.$router.replace(route).catch(() => {});
        // Fix "NavigationDuplicated"
        // See https://github.com/vuejs/vue-router/issues/2872
        this.$nextTick(() => {
          // Save hits
          this.hits = result;
          this.loading = false;
          this.firstLoad = false;
        });
      });
    },
  },
};
</script>

<style lang="scss">
  .filters {
    position: absolute;
    top: 10px;
    left: 55px;
    width: 15%;
    z-index: 7;

    & > i, & > div > .v-card {
      border: 2px solid rgba(0,0,0,0.2);
    }

    & > i {
      font-size: 28px !important;
      background: white;
    }
  }
</style>
